<template>
  <nav>
    <v-app-bar flat dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>Kedas Beauty</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="light-green lighten-3"
            icon
            v-bind="attrs"
            v-on="on"
            href="/settings"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>Settings</span>
      </v-tooltip>
      <v-btn
        text
        :loading="loading"
        :disabled="loading"
        @click="handleLogout"
        color="blue-grey lighten-4"
      >
        <v-icon>mdi-logout</v-icon>
        Logout
      </v-btn>
      <!-- <v-menu left offset-x rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-grid</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item router to="/settings">
            <v-list-item-icon>
              <v-icon left>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content >
            <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon left>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="handleLogout">
            <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
    <v-navigation-drawer dark v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center">{{ user }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list rounded dense class="mt-5">
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            router
            :to="link.route"
            active-class="teal white--text"
          >
            <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list dense rounded>
        <v-list-group
          v-for="(item, i) in items"
          :key="i"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
          color="teal"
                   
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            dense
            v-for="(child, i) in item.items"
            :key="i"
            router
            :to="child.route"
            color="teal"
          >
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: "",
      selectedItem: 1,
      drawer: true,
      loading: false,
      pass: false,
      links: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/dashboard/main",
        },
        {
          title: "Daftar Reseller",
          icon: "mdi-view-list",
          route: "/dashboard/reseller-list",
        },
        {
          title: "Tambah Data Reseller",
          icon: "mdi-database-plus",
          route: "/dashboard/add-reseller",
        },
        {
          title: "Manage Event",
          icon: "mdi-calendar-check",
          route: "/dashboard/manage-event",
        },
      ],
      items: [
        {
          icon: "mdi-file-cog",
          items: [
            {
              title: "Home",
              icon: "mdi-home-edit",
              route: "/dashboard/manage-home-page",
            },
            {
              title: "Products",
              icon: "mdi-file-edit",
              route: "/dashboard/manage-products",
            },
          ],
          title: "Manage",
        },
      ],
    };
  },
  methods: {
    async handleLogout() {
      this.loading = true;
      await axios
        .post(`${process.env.VUE_APP_KB_API_URL}/api/logout`, null, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (!response.status == 401) {
            window.localStorage.removeItem("token");
          } else {
            window.localStorage.removeItem("token");
          }
          console.log(response.data);
          this.$router.push({
            path: "/auth",
          });
          this.pass = true;
        })
        .catch((err) => {
          console.log(err);
        });
      if (this.pass === true) {
        this.loading = false;
      }
    },
    getUser() {
      // console.log(window.localStorage.getItem('name'))
      this.user = window.localStorage.getItem("name");
    },
  },
  created() {
    this.getUser();
  },
};
</script>