<template>
  <v-app>
    <Nav />
    <div class="fill-height light-blue darken-4">
      <transition name="slide-x-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  <Footer/>
  </v-app>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
export default {
  data() {
    return {};
  },
  components: {
    Nav,
    Footer
  },
};
</script>